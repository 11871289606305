<template>
  <div class="act">
    <div class="d-flex flex-column min-vh-100">
      <Evenmenubar :title="title" />
      <b-modal
        ref="myloading"
        id="myloadings"
        title="Loading"
        @hide="onHide"
        centered
        hide-footer
        hide-header
      >
        <div class="text-center">
          <b-alert :variant="variantype" show
            ><strong><span v-text="confirmtxt"></span></strong
          ></b-alert>
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </b-modal>
      <ul>
        <li>
          <div class="mt-3">
            <br />
            步驟 : <span v-text="current + 1"></span>&emsp;<span
              v-text="steps[current].title"
            ></span>
            <Consent
              v-show="steps[current].id == 1"
              @done="done"
              :obj="objs"
              :cobj="cobjs"
              :page="current"
              ref="con1"
            />
            <Organizer
              v-show="steps[current].id == 2"
              @done="done"
              :obj="objs"
              :cobj="cobjs"
              :page="current"
              ref="con2"
            />
            <Option
              v-show="steps[current].id == 3"
              @done="done"
              :obj="objs"
              :cobj="cobjs"              
              :page="current"
              ref="con3"
            />
            <Actypes
              v-show="steps[current].id == 4"
              @done="done"
              :obj="objs"
              :cobj="cobjs"                
              :page="current"
              ref="con4"
            />
            <Mactivity
              v-show="steps[current].id == 5"
              @done="done"
              :obj="objs"
              :cobj="cobjs" 
              :page="current"
              :loadata="stepdata[1]"
              :org="myorg"
              ref="con5"
            />
            <Macdecs
              v-show="steps[current].id == 6"
              @done="done"
              :obj="objs"
              :cobj="cobjs"              
              :page="current"
              ref="con6"
            />
            <Mcoach
              v-show="steps[current].id == 7"
              @done="done"
              @checkstaff="checkstaff"
              :obj="objs"
              :cobj="cobjs"                 
              :page="current"
              ref="con7"
            />
            <Mform
              v-show="steps[current].id == 8"
              @done="done"
              :obj="objs"
              :cobj="cobjs"               
              :page="current"
              ref="con8"
            />
            <Mfee
              v-show="steps[current].id == 9"
              @done="done"
              :obj="objs"
              :cobj="cobjs"               
              :page="current"
              ref="con9"
            />
            <Volunteer
              v-show="steps[current].id == 10"
              @done="done"
              @checkstaff="checkstaff"
              :obj="objs"
              :cobj="cobjs"  
              :page="current"
              ref="con10"
            />
            <Cfm
              v-show="steps[current].id == 11"
              @done="done"
              :obj="objs"
              :page="current"
              ref="con11"
            />

            <div class="steps-action right">
              <a-button
                size="large"
                block
                style="width: 150px"
                v-if="current < steps.length - 1"
                type="primary"
                :disabled="!steps[current].state"
                @click="next"
                >下一步</a-button
              >
              <a-button-group v-if="current == steps.length - 1">
                <a-button
                  size="large"
                  @click="sendform('N')"
                  :disabled="visibley"
                  >存成草稿</a-button
                >
                <a-button
                  size="large"
                  @click="sendform('I')"
                  :disabled="visibley"
                  type="primary"
                >
                  送出審核
                </a-button>
              </a-button-group>
              <a-button
                size="large"
                block
                v-if="current > 0"
                style="margin-left: 8px; width: 150px"
                @click="prev"
              >
                上一步
              </a-button>
              <a-button
                size="large"
                block
                v-if="current>4"
                style="margin-left: 8px; width: 150px"
                @click="saveToTmp"
              >
              <b-icon size="lg" icon="files" ></b-icon>
                暫存
              </a-button>                
            </div>
          </div>
        </li>
      </ul>

      <div class="wrapper flex-grow-1"></div>
      <Efootbar />
    </div>
  </div>
</template>
<script>
import router from "../router";
import Evenmenubar from "@/components/Evenmenubar.vue";
import Efootbar from "@/components/Efootbar.vue";
import Consent from "./Consent";
import Organizer from "./Organizer";
import Option from "./Option";
import Actypes from "./Actypes";
import Mactivity from "./Mactivity";
import Macdecs from "./Macdecs";
import Volunteer from "./Volunteer";
import Mcoach from "./Mcoach";
import Mform from "./Mform";
import Mfee from "./Mfee";
import Cfm from "./Cfm";
import Stepact from "../assets/json/stepact.json";
import { macService } from '../_services'
import { mapState, mapActions } from "vuex";

const delData = params => {
  return macService.macDelTmp(params);
}

export default {
  name: "Act_tmp",
  components: {
    Evenmenubar,
    Efootbar,
    Consent,
    Organizer,
    Option,
    Actypes,
    Mactivity,
    Macdecs,
    Mcoach,
    Volunteer,
    Mform,
    Mfee,
    Cfm,
  },
  data() {
    return {
      disableds: false,
      visibley: false,
      messsage: null,
      saveMyTmp:"",
      title: { title: "主辦活動", logo: "menu-unfold" },
      current: 0,
      steps: Stepact,
      stepdata: [],
      variantype: "warning",
      confirmtxt: "資料處理中...",
      mestate: false,
      mestate2: false,
      tmpid:0,
      objs: {},
      cobjs:{},
      myorg:{},
    };
  },
  watch: {
    mestate(val) {
      if (val) {
        this.$refs["myloading"].hide();
        router.replace({ name: "Center" }).catch((err) => {
          err;
        });
      }
    },
    mestate2(){
        this.$refs["myloading"].hide();
    }
  }, 
  methods: {
    ...mapActions("account", ["logout", "checktoken"]),
    ...mapActions("mact", ["insertmact","insertmactmp","updatetmactmp","getTmpAct"]),
    next() {
      let check = false;
      if (this.steps[this.current].state) {
        check = true;
      }
      this.messsage = "資料填寫不齊全，請確認謝謝 !";
      if (check) {
        this.messsage = null;
        this.current++;
      }
    },
    prev() {
      let check = false;
      if (this.steps[this.current - 1].state) {
        check = true;
      }
      this.messsage = "資料填寫不齊全";
      if (check) {
        this.messsage = null;
        this.current--;
      }
    },

    async saveToTmp(){
      this.$refs["myloading"].show();
      let scoach = [];
      let soption = [];
      let volunteers = [];
      for (var prop in this.stepdata[6]) {
        let tdata = {
          id: this.stepdata[6][prop].id,
          uid: this.stepdata[6][prop].uid,
          name: this.stepdata[6][prop].name,
          displayname: "",
          title: this.stepdata[6][prop].title,
          url: this.stepdata[6][prop].url,
          company: this.stepdata[6][prop].company,
          desc: this.stepdata[6][prop].desc,
          finkurl: this.stepdata[6][prop].finkurl,
          iinkurl: this.stepdata[6][prop].iinkurl,
          center: this.stepdata[6][prop].center,
          checked: this.stepdata[6][prop].checked,
          getscore: this.stepdata[6][prop].getscore,          
        };
        scoach.push(tdata);
      }
      for (var objs in this.stepdata[7]) {
        let tdata = {
          oid: this.stepdata[7][objs].id,
        };
        soption.push(tdata);
      }
      let tmp = [];
      for (var dobj in this.stepdata[8].item) {
        let tdata = {
          id: this.stepdata[8].item[dobj].id,
          desc: this.stepdata[8].item[dobj].desc,
          title: this.stepdata[8].item[dobj].title,
          fee: parseInt(this.stepdata[8].item[dobj].fee),
          url: this.stepdata[8].item[dobj].url,
        };
        tmp.push(tdata);
      }

      let costfee = {
        fee: this.stepdata[8].fee,
        bcode: this.stepdata[8].bcode,
        account: this.stepdata[8].account,
        item: tmp,
      };

      for (var j in this.stepdata[9]) {
        let tdata = {
          id: this.stepdata[9][j].id,
          uid: this.stepdata[9][j].uid,
          name: this.stepdata[9][j].name,
          url: this.stepdata[9][j].url,
          checked: this.stepdata[9][j].checked,
          getscore: this.stepdata[9][j].getscore,          
        };
        volunteers.push(tdata);
      }
      let rs = {
        id:0,
        uid: this.user.data.user.id,
        allow: this.stepdata[0].allow,
        theme: this.stepdata[2].value,
        tid:this.stepdata[3].id,
        title: this.stepdata[3].title,
        vals: this.stepdata[3].vals,
        aid:this.stepdata[3].items_type.id,
        atitle: this.stepdata[3].items_type.title,
        descs: this.stepdata[3].items_type.descs,
        broker: this.stepdata[3].items_type.broker,
        coach: this.stepdata[3].items_type.coach,
        volunteer: this.stepdata[3].items_type.volunteer,
        actor: this.stepdata[3].items_type.actor,
        orgid: this.stepdata[1].id,
        actitle: this.stepdata[4].actitle,
        address: this.stepdata[4].address,
        lat: this.stepdata[4].center.lat,
        lng: this.stepdata[4].center.lng,
        edate: this.stepdata[4].edate,
        sdate: this.stepdata[4].sdate,
        etime: this.stepdata[4].etime.split(":").slice(0, 2).join(":"),
        stime: this.stepdata[4].stime.split(":").slice(0, 2).join(":"),
        email: this.stepdata[4].email,
        sinkurl: this.stepdata[4].sinkurl,
        finkurl: this.stepdata[4].finkurl,
        hphone: this.stepdata[4].hphone,
        mphone: this.stepdata[4].mphone,
        url: this.stepdata[4].url,
        content: this.stepdata[5]!==null? this.stepdata[5].content:"" ,
        desc: this.stepdata[5]!==null? this.stepdata[5].desc:"" ,
        fee : costfee.fee,  
        bcode: costfee.bcode,    
        account: costfee.account, 
        items: costfee.item,
        coachs: scoach,
        options: soption,
        volunteers: volunteers,
        weekeds: this.stepdata[4].weeked,
        checked: this.stepdata[4].limits.checked,
        value: parseInt(this.stepdata[4].limits.value),
        datetype:  this.stepdata[4].datetype.checked,
        multiples: this.stepdata[4].multiple,
        states: "",
      };

      if(this.tmpid>0){
        rs.id=this.tmpid
        await this.updatetmactmp(rs).then((data) => {
        let self = this;
        if (data.code === 201) {
          (this.variantype = "success"),
            (this.confirmtxt = "更新暫存成功"),
            this.tmpid = data.data.id
            setTimeout(() => {
              self.mestate2 = !self.mestate2;
            }, 1500);
          return rs;
        }
      }); 
      }else{
        await this.insertmactmp(rs).then((data) => {
        let self = this;
        if (data.code === 201) {
          (this.variantype = "success"),
            (this.confirmtxt = "活動暫存成功"),
            this.tmpid = data.data.id
            setTimeout(() => {
              self.mestate2 = true;
            }, 1500);

          return rs;
        }
      }); 
      }
    },

    checkstaff(va1, va2) {  
      let v1 = this.$refs["con7"].menber.filter((el) => {
        return el.uid === va1[0].id;
      }).length;
      let v2 = this.$refs["con10"].menber.filter((el) => {
        return el.uid === va1[0].id;
      }).length;
      if (v1 == 0 && v2 == 0) {
        if (va2 == "coach") {
          this.$refs["con7"].merrors = "";
          this.$refs["con7"].form.name =
            va1[0].username == "" ? va1[0].display_name : va1[0].username;
          this.$refs["con7"].form.url = va1[0].pri_url;
          this.$refs["con7"].form.uid = va1[0].id;
        } else {
          this.$refs["con10"].merrors = "";
          this.$refs["con10"].form.name =
            va1[0].username == "" ? va1[0].display_name : va1[0].username;
          this.$refs["con10"].form.url = va1[0].pri_url;
          this.$refs["con10"].form.uid = va1[0].id;
        }
      } else {
        let tmp = va1[0].username == "" ? va1[0].display_name : va1[0].username;
        if (va2 == "coach") {
          this.$refs["con7"].merrors = tmp + "該人員已被設定講師或志工";
        } else {
          this.$refs["con10"].merrors = tmp + "該人員已被設定講師或志工";
        }
      }
    },

    async sendform(va1) {
      this.$refs["myloading"].show();
      let scoach = [];
      let soption = [];
      let volunteers = [];
      for (var prop in this.stepdata[6]) {
        let tdata = {
          id: this.stepdata[6][prop].id,
          uid: this.stepdata[6][prop].uid,
          name: this.stepdata[6][prop].name,
          displayname: "",
          title: this.stepdata[6][prop].title,
          url: this.stepdata[6][prop].url,
          company: this.stepdata[6][prop].company,
          desc: this.stepdata[6][prop].desc,
          finkurl: this.stepdata[6][prop].finkurl,
          iinkurl: this.stepdata[6][prop].iinkurl,
          center: this.stepdata[6][prop].center,
          checked: this.stepdata[6][prop].checked,
          getscore: this.stepdata[6][prop].getscore,          
        };
        scoach.push(tdata);
      }
      for (var objs in this.stepdata[7]) {
        let tdata = {
          oid: this.stepdata[7][objs].id,
        };
        soption.push(tdata);
      }
      let tmp = [];
      for (var dobj in this.stepdata[8].item) {
        let tdata = {
          id: this.stepdata[8].item[dobj].id,
          desc: this.stepdata[8].item[dobj].desc,
          title: this.stepdata[8].item[dobj].title,
          fee: parseInt(this.stepdata[8].item[dobj].fee),
          url: this.stepdata[8].item[dobj].url,
        };
        tmp.push(tdata);
      }

      let costfee = {
        fee: this.stepdata[8].fee,
        bcode: this.stepdata[8].bcode,
        account: this.stepdata[8].account,
        item: tmp,
      };

      for (var j in this.stepdata[9]) {
        let tdata = {
          id: this.stepdata[9][j].id,
          uid: this.stepdata[9][j].uid,
          name: this.stepdata[9][j].name,
          url: this.stepdata[9][j].url,
          checked: this.stepdata[9][j].checked,
          getscore: this.stepdata[9][j].getscore,          
        };
        volunteers.push(tdata);
      }

      let rs = {
        uid: this.user.data.user.id,
        allow: this.stepdata[0].allow,
        theme: this.stepdata[2].value,
        tid:this.stepdata[3].id,
        title: this.stepdata[3].title,
        vals: this.stepdata[3].vals,
        aid:this.stepdata[3].items_type.id,
        atitle: this.stepdata[3].items_type.title,
        descs: this.stepdata[3].items_type.descs,
        broker: this.stepdata[3].items_type.broker,
        coach: this.stepdata[3].items_type.coach,
        volunteer: this.stepdata[3].items_type.volunteer,
        actor: this.stepdata[3].items_type.actor,
        orgid: this.stepdata[1].id,
        actitle: this.stepdata[4].actitle,
        address: this.stepdata[4].address,
        lat: this.stepdata[4].center.lat,
        lng: this.stepdata[4].center.lng,
        edate: this.stepdata[4].edate,
        sdate: this.stepdata[4].sdate,
        etime: this.stepdata[4].etime.split(":").slice(0, 2).join(":"),
        stime: this.stepdata[4].stime.split(":").slice(0, 2).join(":"),
        email: this.stepdata[4].email,
        sinkurl: this.stepdata[4].sinkurl,
        finkurl: this.stepdata[4].finkurl,
        hphone: this.stepdata[4].hphone,
        mphone: this.stepdata[4].mphone,
        url: this.stepdata[4].url,
        content: this.stepdata[5].content,
        desc: this.stepdata[5].desc,
        fee : costfee.fee,  
        bcode: costfee.bcode,    
        account: costfee.account, 
        items: costfee.item,
        coachs: scoach,
        options: soption,
        volunteers: volunteers,
        weekeds: this.stepdata[4].weeked,
        checked: this.stepdata[4].limits.checked,
        value: parseInt(this.stepdata[4].limits.value),
        datetype:  this.stepdata[4].datetype.checked,
        multiples: this.stepdata[4].multiple,
        states: va1,
      };
      await this.insertmact(rs).then((data) => {
        let self = this;
        if(self.cobjs.id>0 && data.code === 201){
            self.updata({id:self.cobjs.id})
        } 
        if (data.code === 201) { 
            (this.variantype = "success"),
            (this.confirmtxt = "活動新增成功"),
            setTimeout(() => {
              self.mestate = true; 
            }, 2000);            
          return rs;
        }
      });
    },

    done(rs) {
      this.steps[rs.pages].state = rs.state;
      this.stepdata[rs.pages] = rs.data;
      if(rs.pages==1){
        this.myorg = rs.data
      }

    },

    getdata() {
      this.$refs.con2.getdata();
      //console.log(rs)
    },

    async inidata(obj) {
      this.steps = this.steps.map((x) => {
        x.state = false;
        return x;
      });
      this.$refs["myloading"].show();
      await this.getTmpAct(obj).then((data) => {
        let self = this;
        if (data.status === 200) {
          (this.variantype = "success"),
            (this.confirmtxt = "取得資料中..."),
            this.tmpid = data.data.id
            this.cobjs = data.data
            setTimeout(() => {
              self.mestate2 = !self.mestate2; 
            }, 1500); 
        }
      });       

    },

    async updata(params={}){
     return await delData({
        ...params,
      }).then(({ data }) => {  
        let obj = data;      
        return obj
      });
    },    

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        //this.handleBackdrop();
      }
    },
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    nameState() {
        return this.saveMyTmp!=='' ? true : false
    },     
  },
  mounted() {
    let tid = this.$route.params.id
    if (this.user == null || tid == null || tid == undefined) {
      router.replace("/");
    } else {
      this.checktoken();
    }
    this.inidata({id:tid});
  },
  created() {},
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>