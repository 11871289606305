<template>
    <div>   
            <br>
            <b-card
                header="活動資料, 填妥請請送出表單"
                border-variant="light"
                header-border-variant="light"
                header-bg-variant="light"
                align="center"   
                class="card-3"         
                >

            <b-card-body style="width:980px;padding:5px 0px 5px 0px;" class="overflow-auto">
            <b-card-text > 
                <img class="bimg" :src="xurl!==''? xurl:catImg('bg_1080x540.png')" /> 
            <div class="row" >

            </div>  
            </b-card-text>
            </b-card-body>
            </b-card>
            <br>
    </div>
</template>
<script>
export default {
    name: 'Cfm',
    order: 3,
    components : {

    },
    props:{
          page:{
              type:Number
          },
        },    
    data(){
    return {
        xurl :'', 
        form:{},      
        }; 
    },
    computed:{

    },
    methods :{
        catImg(imgs) {
            return require(`../assets/${imgs}`);
        }, 

            
    },
    mounted () {

    },
    
}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}

.ddiv{
    padding:7px;
    border-radius: 17px;
    margin: 5px auto;
    box-shadow: 1px 1px 15px -5px rgb(117, 108, 108);
	transition: all .3s ease;
}

.bimg {
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: relative;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}
.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
</style>